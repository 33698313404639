/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-07-24",
    versionChannel: "nightly",
    buildDate: "2023-07-24T00:19:57.431Z",
    commitHash: "cd9efb2dfa8a8e034bd6a1bd65df099d9f4b8bba",
};
